class DecoAnim {
  constructor(target) {
    this.target = target;
    this.items = [...document.getElementsByClassName(target)];
    if(this.items.length > 0) this.init();
  }
  init() {
    this.setAnim();
  }
  setAnim() {
    this.items.forEach(item => {
      switch(item.dataset.anim) {
        case 'right':
          this.toRight(item);
          break;
        case 'left':
          this.toLeft(item);
          break;
        case 'up':
          this.toTop(item);
          break;
        case 'down':
          this.toBottom(item);
          break;
      }
    });
  }
  toRight(item) {
    gsap.set(item, {
      translateX: '-15%',
      opacity: 0,
    });
    gsap.to(item, {
      scrollTrigger: item,
      opacity: 1,
      duration: 2
    })
    gsap.to(item, {
      translateX: '15%',
      scrollTrigger: {
        trigger: item,
        scrub: true,
      },
      ease: 'liner'
    });
  }
  toLeft(item) {
    gsap.set(item, {
      translateX: '15%',
      opacity: 0,
    });
    gsap.to(item, {
      scrollTrigger: item,
      opacity: 1,
      duration: 2
    })
    gsap.to(item, {
      translateX: '-15%',
      scrollTrigger: {
        trigger: item,
        scrub: true,
      },
      ease: 'liner'
    });
  }
  toTop(item) {
    gsap.set(item, {
      translateY: '15%',
    });
    gsap.to(item, {
      scrollTrigger: item,
      opacity: 1,
      duration: 2
    })
    gsap.to(item, {
      translateY: '-15%',
      scrollTrigger: {
        trigger: item,
        scrub: true,
      }
    });
  }
  toBottom(item) {
    gsap.set(item, {
      translateY: '-15%',
    });
    gsap.to(item, {
      scrollTrigger: item,
      opacity: 1,
      duration: 2
    })
    gsap.to(item, {
      translateY: '15%',
      scrollTrigger: {
        trigger: item,
        scrub: true,
      }
    });
  }
}
