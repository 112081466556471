// globals
class Header {
  constructor(target) {
    this.target = target;
    this.el = document.getElementById(target);
    this.menuTriggerClass = 'js-menu-open';
    if(target) this.init();
  }
  init() {
    this.menu();
  }
  menu() {
    this.menuBtn = document.getElementById(`${this.target}-menubtn`);
    this.menuEl = document.getElementById(`${this.target}-menu`);
    if(!this.menuBtn && !this.menuEl) return;
    this.menuBtn.addEventListener('click', () => {
      const isOpen = this.el.classList.contains(this.menuTriggerClass);
      if(isOpen) {
        this.menuClose();
      } else {
        this.menuOpen();
      }
    });
  }
  menuOpen() {
    $(this.menuEl).slideDown();
    this.el.classList.add(this.menuTriggerClass);
  }
  menuClose() {
    $(this.menuEl).slideUp();
    this.el.classList.remove(this.menuTriggerClass);
  }
}

class Accordion {
  constructor(target) {
    this.target = target;
    this.openClass = 'js-is-open';
    this.start();
  }
  start() {
    this.accordions = [...document.getElementsByClassName(this.target)];
    if(this.accordions.length > 0) this.init();
  }
  init() {
    this.setClickEv();
  }
  setClickEv() {
    const _this = this;
    this.accordions.forEach(toggleNav => {
      toggleNav.addEventListener('click',(e) => {
        _this.event(e.currentTarget);
      });
    });
  }
  event(target) {
    const isOpen = target.classList.contains(this.openClass);
    if(!isOpen) {
      this.open(target);
    } else {
      this.close(target)
    }
  }
  open(target) {
    const list = target.nextElementSibling;
    target.classList.add(this.openClass);
    $(list).slideDown();
  }
  close(target) {
    const list = target.nextElementSibling;
    target.classList.remove(this.openClass);
    $(list).slideUp();
  }
}

class Inview {
  constructor(target) {
    this.target = target;
    this.up = [...document.getElementsByClassName(target + '-up')];
    this.fadein = [...document.getElementsByClassName(target + '-fadein')];
    if(this.up.length > 0) this.inviewUp();
    if(this.fadein.length > 0) this.inviewFadein();
  }
  inviewUp() {
    this.up.forEach(el => {
      gsap.set( el, {
        opacity: 0,
        translateY: '30px'
      });
      gsap.to(el, {
        scrollTrigger: el,
        opacity: 1,
        translateY: '0',
        duration: 1,
        ease: Sine.easeOut
      });
    });
  }
  inviewFadein() {
    this.fadein.forEach(el => {
      gsap.set( el, {
        opacity: 0,
      });
      gsap.to(el, {
        scrollTrigger: el,
        opacity: 1,
        duration: 1,
        ease: Sine.easeOut
      });
    });
  }
}

class DecoAnim {
  constructor(target) {
    this.target = target;
    this.items = [...document.getElementsByClassName(target)];
    if(this.items.length > 0) this.init();
  }
  init() {
    this.setAnim();
  }
  setAnim() {
    this.items.forEach(item => {
      switch(item.dataset.anim) {
        case 'right':
          this.toRight(item);
          break;
        case 'left':
          this.toLeft(item);
          break;
        case 'up':
          this.toTop(item);
          break;
        case 'down':
          this.toBottom(item);
          break;
      }
    });
  }
  toRight(item) {
    gsap.set(item, {
      translateX: '-15%',
      opacity: 0,
    });
    gsap.to(item, {
      scrollTrigger: item,
      opacity: 1,
      duration: 2
    })
    gsap.to(item, {
      translateX: '15%',
      scrollTrigger: {
        trigger: item,
        scrub: true,
      },
      ease: 'liner'
    });
  }
  toLeft(item) {
    gsap.set(item, {
      translateX: '15%',
      opacity: 0,
    });
    gsap.to(item, {
      scrollTrigger: item,
      opacity: 1,
      duration: 2
    })
    gsap.to(item, {
      translateX: '-15%',
      scrollTrigger: {
        trigger: item,
        scrub: true,
      },
      ease: 'liner'
    });
  }
  toTop(item) {
    gsap.set(item, {
      translateY: '15%',
    });
    gsap.to(item, {
      scrollTrigger: item,
      opacity: 1,
      duration: 2
    })
    gsap.to(item, {
      translateY: '-15%',
      scrollTrigger: {
        trigger: item,
        scrub: true,
      }
    });
  }
  toBottom(item) {
    gsap.set(item, {
      translateY: '-15%',
    });
    gsap.to(item, {
      scrollTrigger: item,
      opacity: 1,
      duration: 2
    })
    gsap.to(item, {
      translateY: '15%',
      scrollTrigger: {
        trigger: item,
        scrub: true,
      }
    });
  }
}

class Contact {
  constructor(target) {
    this.items = [...document.getElementsByClassName(target + '-check')];
    if(this.items.length > 0) this.init();
  }

  init() {
    const param = this.getURLParam('slug');
    if(param) this.checkTarget(param);
  }

  checkTarget(target) {
    this.items.forEach(item => {
      if(item.value !== target) return;
      item.checked = true;
    });
  }

  getURLParam(name,url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
}

class Slider {
  constructor(target) {
    this.items = [...document.getElementsByClassName(target)];
    if(this.items.length > 0) this.init();
  }
  init() {
    this.items.forEach(item => {
      switch (item.dataset.slide) {
        case 'work-detail':
          this.workDetail(item);
          break;
        case 'mv':
          this.mvSlider(item);
          break;
      }
    });
  }
  workDetail(item) {
    const slides = [...item.getElementsByClassName('swiper-slide')];
    if(slides.length <= 1) {
      [...item.getElementsByClassName('swiper-pagination')].forEach(el => {
        el.style.display = 'none';
      });
      return;
    };
    const slider = new Swiper(item, {
      autoplay: {
        delay: 3000,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    });
  }
  mvSlider(item) {
    const slides = [...item.getElementsByClassName('swiper-slide')];
    if(slides.length > 1) {
      const slider = new Swiper(item, {
        loop: true,
        speed: 1500,
        autoplay: {
          delay: 3000,
        },
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
      });
    }

  }
}

class AnchorScroll {
  constructor() {
    this.start();
  }
  start() {
    this.init();
  }
  init() {


    const anchor = location.hash;
    console.log(anchor);
    if(anchor) {
      gsap.to( window, {
        duration: 0,
        scrollTo: $(window).scrollTop() - $('#js-header').innerHeight()
      });
    }

    $('a[href^="#"]').on('click', (event) => {
      console.log('cli');
      event.preventDefault();
      const target = event.target.getAttribute('href');
      const targetPos = $(target)[0].getBoundingClientRect().top + window.pageYOffset;
      gsap.to( window, {
        duration: 1,
        scrollTo: targetPos -  $('#js-header').innerHeight()
      });
    });
  }
}



(() => {

})();

//DOMContentLoaded
$(() => {
  const anchorscroll = new AnchorScroll();
  const inview = new Inview('js-inview');

});

//images resources loaded
$(window).on('load', () => {



  $(window).trigger('loading');
});

//after loading animation
$(window).on('loading', () => {
  const header = new Header('js-header');
  const acc = new Accordion('js-accordion');
  const decoAnim = new DecoAnim('js-deco');
  const item = new Contact('js-contact');
  const slider = new Slider('js-slider');
});
