class Inview {
  constructor(target) {
    this.target = target;
    this.up = [...document.getElementsByClassName(target + '-up')];
    this.fadein = [...document.getElementsByClassName(target + '-fadein')];
    if(this.up.length > 0) this.inviewUp();
    if(this.fadein.length > 0) this.inviewFadein();
  }
  inviewUp() {
    this.up.forEach(el => {
      gsap.set( el, {
        opacity: 0,
        translateY: '30px'
      });
      gsap.to(el, {
        scrollTrigger: el,
        opacity: 1,
        translateY: '0',
        duration: 1,
        ease: Sine.easeOut
      });
    });
  }
  inviewFadein() {
    this.fadein.forEach(el => {
      gsap.set( el, {
        opacity: 0,
      });
      gsap.to(el, {
        scrollTrigger: el,
        opacity: 1,
        duration: 1,
        ease: Sine.easeOut
      });
    });
  }
}
