class Accordion {
  constructor(target) {
    this.target = target;
    this.openClass = 'js-is-open';
    this.start();
  }
  start() {
    this.accordions = [...document.getElementsByClassName(this.target)];
    if(this.accordions.length > 0) this.init();
  }
  init() {
    this.setClickEv();
  }
  setClickEv() {
    const _this = this;
    this.accordions.forEach(toggleNav => {
      toggleNav.addEventListener('click',(e) => {
        _this.event(e.currentTarget);
      });
    });
  }
  event(target) {
    const isOpen = target.classList.contains(this.openClass);
    if(!isOpen) {
      this.open(target);
    } else {
      this.close(target)
    }
  }
  open(target) {
    const list = target.nextElementSibling;
    target.classList.add(this.openClass);
    $(list).slideDown();
  }
  close(target) {
    const list = target.nextElementSibling;
    target.classList.remove(this.openClass);
    $(list).slideUp();
  }
}
