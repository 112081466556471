class AnchorScroll {
  constructor() {
    this.start();
  }
  start() {
    this.init();
  }
  init() {


    const anchor = location.hash;
    console.log(anchor);
    if(anchor) {
      gsap.to( window, {
        duration: 0,
        scrollTo: $(window).scrollTop() - $('#js-header').innerHeight()
      });
    }

    $('a[href^="#"]').on('click', (event) => {
      console.log('cli');
      event.preventDefault();
      const target = event.target.getAttribute('href');
      const targetPos = $(target)[0].getBoundingClientRect().top + window.pageYOffset;
      gsap.to( window, {
        duration: 1,
        scrollTo: targetPos -  $('#js-header').innerHeight()
      });
    });
  }
}
