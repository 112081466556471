class Contact {
  constructor(target) {
    this.items = [...document.getElementsByClassName(target + '-check')];
    if(this.items.length > 0) this.init();
  }

  init() {
    const param = this.getURLParam('slug');
    if(param) this.checkTarget(param);
  }

  checkTarget(target) {
    this.items.forEach(item => {
      if(item.value !== target) return;
      item.checked = true;
    });
  }

  getURLParam(name,url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
}
