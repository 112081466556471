class Slider {
  constructor(target) {
    this.items = [...document.getElementsByClassName(target)];
    if(this.items.length > 0) this.init();
  }
  init() {
    this.items.forEach(item => {
      switch (item.dataset.slide) {
        case 'work-detail':
          this.workDetail(item);
          break;
        case 'mv':
          this.mvSlider(item);
          break;
      }
    });
  }
  workDetail(item) {
    const slides = [...item.getElementsByClassName('swiper-slide')];
    if(slides.length <= 1) {
      [...item.getElementsByClassName('swiper-pagination')].forEach(el => {
        el.style.display = 'none';
      });
      return;
    };
    const slider = new Swiper(item, {
      autoplay: {
        delay: 3000,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    });
  }
  mvSlider(item) {
    const slides = [...item.getElementsByClassName('swiper-slide')];
    if(slides.length > 1) {
      const slider = new Swiper(item, {
        loop: true,
        speed: 1500,
        autoplay: {
          delay: 3000,
        },
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
      });
    }

  }
}
