class Header {
  constructor(target) {
    this.target = target;
    this.el = document.getElementById(target);
    this.menuTriggerClass = 'js-menu-open';
    if(target) this.init();
  }
  init() {
    this.menu();
  }
  menu() {
    this.menuBtn = document.getElementById(`${this.target}-menubtn`);
    this.menuEl = document.getElementById(`${this.target}-menu`);
    if(!this.menuBtn && !this.menuEl) return;
    this.menuBtn.addEventListener('click', () => {
      const isOpen = this.el.classList.contains(this.menuTriggerClass);
      if(isOpen) {
        this.menuClose();
      } else {
        this.menuOpen();
      }
    });
  }
  menuOpen() {
    $(this.menuEl).slideDown();
    this.el.classList.add(this.menuTriggerClass);
  }
  menuClose() {
    $(this.menuEl).slideUp();
    this.el.classList.remove(this.menuTriggerClass);
  }
}
